import { datadogRum } from '@datadog/browser-rum';
import {ENV,DD_CLIENT_TOKEN} from './constants/config';


datadogRum.init({
    applicationId: 'd54e3694-3093-450e-a22d-e3b70fed6b4e',
    clientToken: DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'minta-fe',
    env: ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});