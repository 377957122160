declare const process: {
  env: {
    [key: string]: string;
  };
};

// need to check APPLICATION_SOURCE
export const ENV = process.env.REACT_APP_NODE_ENV;
export const DD_CLIENT_TOKEN = process.env.REACT_APP_DD_CLIENT_TOKEN;
export const APPLICATION_SOURCE = process.env.REACT_APP_APPLICATION_SOURCE || 'mobile';
export const API_URL: string = process.env.REACT_APP_API_URL;
export const APP_URL: string = process.env.REACT_APP_URL;
export const HOME_PAGE_URL: string = process.env.REACT_APP_HOME_PAGE_URL;
export const IS_DEBUG_MODE = Boolean(process.env.REACT_APP_DEBUG);
export const SHOPIFY_STORE_URL = process.env.REACT_APP_SHOPIFY_STORE_URL;
export const BIG_COMMERCE_STORE_URL = process.env.REACT_APP_BIG_COMMERCE_STORE_URL;
export const WOO_CALLBACK = process.env.REACT_APP_WOO_CALLBACK;
export const INTERCOM_ID = process.env.REACT_APP_INTERCOM_ID;

export const ASSETS_CDN_URL = 'assets.withminta.com';
export const USER_IMAGES_CDN_URL = 'dsrl9mo3gpvja.cloudfront.net';

export const TIKTOK_ADS_URL = process.env.REACT_APP_TIKTOK_ADS_URL;
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
export const MESSENGER_APP_ID = process.env.REACT_APP_MESSENGER_APP_ID;
export const FB_PAGE_ID = process.env.REACT_APP_FB_PAGE_ID;
export const VIDEO_FETCH_LIMIT = 8;
export const VIDEO_MESSAGE_FETCH_LIMIT = 8;
export const PRODUCT_FETCH_LIMIT = 10;
export const PRODUCT_FETCH_LIMIT_CUSTOM = 100;
export const INITIAL_VIDEO_FETCH_DELAY = 2500;
export const API_REQ_RETRY_DELAY = 3000;
export const LIMIT_USER_IMAGES_CALL = 30;
export const SESSION_STORAGE_REDIRECT_AFTER_SIGNIN = 'redirectAfterSignin';
export const SESSION_STORAGE_REDIRECT_AFTER_PAYMENT = 'redirectAfterPayment';
export const SESSION_STORAGE_LATEST_FEATURE_DATA = 'latestFeatureData';
export const SHOPIFY_APP_CLIENT_ID = process.env.REACT_APP_SHOPIFY_APP_CLIENT_ID;
export const BIG_COMMERCE_APP_ID = process.env.REACT_APP_BIG_COMMERCE_APP_ID;
export const PADDLE_ENV = process.env.REACT_APP_PADDLE_ENV;
export const PADDLE_VENDOR_ID = process.env.REACT_APP_PADDLE_VENDOR_ID;
export const WIX_APP_ID = process.env.REACT_APP_WIX_APP_ID;
export const WIX_APP_STORE_URL = process.env.REACT_APP_WIX_APP_STORE_URL;
export const TIKTOK_API_URL = process.env.REACT_APP_TIKTOK_API_URL;
export const TIKTOK_CLIENT_KEY = process.env.REACT_APP_TIKTOK_CLIENT_KEY;
export const REMOVE_BG_URL = process.env.REACT_APP_REMOVE_BG_URL;
// Currently we allow all origins when sending postMessage via the window object. will update it in the future after the relevant domains will be configured for the minta-editor and button repos.
export const ALLOWED_ORIGIN = '*';
export const EMAIL_TO_CONTACT = process.env.REACT_APP_EMAIL_TO_CONTACT;
export const EMAIL_SUBJECT_TO_CONTACT = process.env.REACT_APP_EMAIL_SUBJECT_TO_CONTACT;
export const IS_PROD = process.env.REACT_APP_IS_PROD;
export const UPPY_COMPANION_URL = process.env.REACT_APP_UPPY_COMPANION_URL;
// Paddle Billing
export const PADDLE_BILLING = {
   CLIENT_TOKEN: process.env.REACT_APP_PADDLE_BILLING_CLIENT_TOKEN
};