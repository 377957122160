import React from 'react';
import ReactDOM from 'react-dom';
import './dd-rum';
import * as serviceWorker from 'serviceWorker';
// import * as Sentry from '@sentry/browser';
// import { SENTRY_DNS } from 'constants/config';

// import { version } from '../package.json';
import { AppWindow } from 'types/common.types';
// import { MODAL_NAME } from 'constants/route.constants';
// import { store } from 'redux/store';
// import { push } from 'connected-react-router';
// import { getQuery, setQuery } from 'utils/query.utils';

// sentry dev
// Sentry.init({
//   release: version,
//   dsn: SENTRY_DNS,
// });

const importBuildTarget = () => {
  if (process.env.REACT_APP_BUILD_TARGET === 'player') {
    return import('EmbeddedPlayer');
  }
  // default app
  return import('MintaApp');
  // return Promise.reject(
  //   new Error(`No such build target: ${process.env.REACT_APP_BUILD_TARGET}`)
  // );
};

// Import the entry point and render it's default export
importBuildTarget().then(({ default: Environment }) =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(<Environment />, document.getElementById('root'))
);

// **** service worker start - version control *****

declare const window: AppWindow;

// const showUpdateDialog = () => {
//   const { search } = window.location;
//   const currentModalName = getQuery(search, 'modal');
//   if (currentModalName !== MODAL_NAME.UPDATE_VERSION) {
//     const query = setQuery(search, {

//       modal: MODAL_NAME.UPDATE_VERSION,
//     });
//     const action = push({ search: query });
//     store.dispatch(action);
//   }
// };

const config: serviceWorker.ServiceWorkerRegistrationConfig = {
  onRegister: (registration: ServiceWorkerRegistration) => {
    window.updateWorker = registration;
    const needToUpdate = !!registration.waiting;
    if (needToUpdate) {
      console.log('new version ready');
      // setTimeout(() => showUpdateDialog(), 3000);
    }

    // check for update
    window.onfocus = () => {
      const needToUpdate = !!registration.waiting;
      if (needToUpdate) {
        // setTimeout(() => showUpdateDialog(), 3000);
      } else {
        registration.update();
      }
    };
  },
  onUpdate: (registration: ServiceWorkerRegistration) => {
    console.log('new version ready');
    // showUpdateDialog();
  },
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (window.Cypress || process.env.REACT_APP_BUILD_TARGET === 'player') {
  // don't register worker on test
  serviceWorker.unregister();
} else {
  serviceWorker.register(config);
}

// **** service worker end - version control *****
